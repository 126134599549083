@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "SF Pro Display";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://images.unsplash.com/photo-1498036882173-b41c28a8ba34?ixlib=rb-1.2.1ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80");

  background-position: center;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  height: 5px;
  position: relative;
}
